import React from 'react'
import "./DetailArtikel.scss";
import Berita from '../../assets/images/berita.jpeg';
import Berita1 from '../../assets/images/berita2.jpg';
import Berita2 from '../../assets/images/berita3.jpg';
import { Link, useParams } from 'react-router-dom';
import FeatherIcon from "feather-icons-react";

const DetailArtikel = () => {
  const {id} = useParams()
  return (
    <section id="DetailArtikel">
      <div className="navbar">
        <Link to={"/"} className="link-back">
          <FeatherIcon icon="arrow-left"/>
          Kembali
        </Link>
      </div>
      <div className="container">
        
        <div className="news-content">
          {id === "Pj-Bupati-Muara-Enim-Apresiasi-Peringatan-Hari-Jadi-ke-96-Desa-Tegal-Rejo" ? 
          <>
          <h2>Pj Bupati Muara Enim Apresiasi Peringatan Hari Jadi ke-96 Desa Tegal Rejo</h2>
            <p className='news-date'>Kamis, 29/12/2023, 21.00 WIB</p>
            <img src={Berita} alt="News Image" />
            <p>TahSetelah mendengarkan secara langsung sambutan dari perwakilan masyarakat Desa Tegal Rejo dalam hal ini Anggota DPRD Kabupaten Muara Enim Dwi Windarti mengenai Peringatan Hari Jadi Desa Tegal Rejo ke-96 bertepatan pada Tahun Baru Islam atau tanggal 1 Muharram 1444 Hijriyah yang mana dirinya menyampaikan bahwa dalam peringatan kali ini Desa Tegal Rejo menggelar kegiatan selama 10 hari berturut-turut sejak tanggal 1 Muharram s.d 10 Muharram diantaranya pawai ta'aruf yang mengelilingi Desa Tegal Rejo, festival seni budaya, Festival musik dan lagu tradisional dan masih banyak lagi kegiatan-kegiatan lainnya.
              </p>
              <p>
            Mendengar penjelasan tersebut, Pj Bupati Muara Enim langsung bertepuk tangan dengan gembira sebagai bentuk apresiasi dan rasa terima kasih kepada seluruh masyarakat Desa Tegal Rejo yang telah menjaga budaya adat istiadat warisan nenek moyang dibuktikan dengan berbagai macam kegiatan yang menunjukkan kearifan lokal di Desa Tegalrejo.
            </p>
            <p>
            Lebih dari itu, selaku Kepala Daerah Pj Bupati pun ikut ambil bagian dalam perayaan tersebut dengan memberikan support berupa uang tunai senilai 10 juta rupiah dengan harapan dukungan tersebut dapat dipergunakan untuk lebih memeriahkan perayaan hari jadi Desa Tegal Rejo apalagi bersama dengan perayaan atau peringatan hari ulang tahun Kemerdekaan Republik Indonesia yang ke-77.
            </p>
            <p>
            Tak lupa, diakhir sambutannya, Pj Bupati mengajak semua masyarakat Desa Tegal Rejo untuk bersama-sama mensukseskan program pembangunan di Kabupaten Muara Enim apalagi di Tegal Rejo (Tanjung Enim) akan dijadikan kota tujuan wisata oleh PT Bukit Asam Tbk.
            </p>
            <p>
            "Tentu untuk mewujudkan tujuan tersebut Pemkab. Muara Enim ataupun PTBA tidaklah sanggup bergerak sendirian, maka perlu bantuan dan dukungan dari semua kalangan masyarakat tanpa terkecuali masyarakat di Desa Tegal Rejo," pungkas Pj Bupati.
            </p>
            <p>
            Hadir mendampingi Pj Bupati diantaranya Pj Sekda Muara Enim, beberapa Kepala Perangkat Daerah Lingkup Pemkab. Muara Enim, Camat Lawang Kidul beserta Forkopimcam Lawang Kidul, Kades Tegal Rejo, Tokoh Agama, Tokoh Adat, dan pemangku kepentingan di Desa Tegal Rejo.
            </p>

            <span>Sumber Berita: <a style={{color:"blue"}} href='https://muaraenimkab.go.id/web/detail_berita/2797' target="_blank">https://muaraenimkab.go.id/web/detail_berita/2797</a></span>
          </> 
          : 
          <>
          <h2>Kajari Batu Operasi Pasar Murah di Desa Tegalrejo Muara Enim</h2>
            <p className='news-date'>TegalRejo, 08/04/2024, 09:23 WIB</p>
            <img src={Berita1} alt="News Image" />
            <p> Dalam rangka menekan pengendalian Inflasi Daerah Tahun 2024 Kejaksaan Negeri Muara Enim bersama dengan Pemerintah Kabupaten Muara Enim gelar Operasi Pasar Murah di Gedung Balai Adat Desa Tegalrejo Kecamatan lawang kidul Kabupaten Muara Enim. Kamis, (7/3/2024).</p>
                <p>
                ” Alhamdulillah, operasi pasar murah yang kami gelar hari ini, habis terjual dan antusiasme masyarakat sangat tinggi sekali, dimana kegiatan yang kami lakukan sebagai bentuk dukungan kami guna menekan inflasi di masyarakat ,” kata Kajari Muara Enim Ahmad Nuril Alam SH MH.
                </p>
                <p>
                Nuril Alam menjelaskan, kegiatan pasar murah yang di lakukan oleh jajaran nya tersebut berasal dari Bulog dalam upaya Pengendalianbnflasi Daerah Kabupaten Muara Enim Tahun 2024 terdiri dari 7 item kebutuhan pokok pangan di masyarakat di antara nya yaitu Beras 20 Ton, Ayam potong, Telur ayam, Minyak Goreng, Gula Susu dan Cabai.
                </p>
                <p>
                “Yang jelas harga yang di jual ini pasti nya di bawah net harga pasar dan di peruntukan bagi masyarakat yang kurang mampu ,” jelasnya.
                </p>
                <p>
                Lebih jauh Nuril Alam menegaskan, dalam kegiatan tersebut akan di lakukan secara berkesinambung bersama Pemkab Muara Enim sebagai bentuk pengawasan menstabilitaskan harga pokok pangan di masyarakat.
                </p>
                <p>
              ” Langkah Kongkrit ini, Inshaa Allah akan trus kami lakukan bersama Pemkab Muara Enim dalam rangka pengendalian inflasi di Kabupaten Muara Enim, mudah-mudahan kedepan harga bahan pokok pangan di masyarakat dapat stabil terjaga ,” tegasnya.
              </p>
              <p>
              Dalam pantuan di lapangan hadir secara lamgsung kegiatan tersebut Kepala Kejaksaan Negeri Muara Enim Ahmad Nuril Alam, SH.MH, Ketua Iad Kabupaten Muara Enim Ny Eva Nuril Alam ,Kasi Intelijen Kejari Muara Enim Anjasra Karya, SH.MH, Dandim 0404 Muara Enim Letkol Inf Nugraha SH MIP, Kapolres Muara Enim Asisten II Pemerintah Kabupaten Muara Enim dan para Anggota Kejaksaan Negeri Muara Enim serta Anggota Iad Kabupaten Muara Enim.(dr)
              </p>
              <br/>
              <p>
              <span>Sumber Berita: <a style={{color:"blue"}} href='https://enimpost.com/berita/kajari-batu-operasi-pasar-murah-di-desa-tegalrejo-muara-enim/' target="_blank">https://enimpost.com/berita/kajari-batu-operasi-pasar-murah-di-desa-tegalrejo-muara-enim/</a></span>
              </p>
          </>
          }
         </div>
        {/* <div className="news-list">
          <p className="orange-elipse">Other News</p>
          <ul>
            <li>
              <a href="news1.html">
                <div className="news-card">
                  <div className='news-image'>
                    <img src={Hutdesa} alt="News Image" />
                  </div>
                  
                  <div className="news-text">
                    <h6>Lorem ipsum dolor, sit amet consectetur adipisicing elit. </h6>
                    <p>Jumat, 17/03/2023, 21.00 WIB</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="news2.html">
                <div className="news-card">
                  <div className='news-image'>
                    <img src={Hutdesa} alt="News Image" />
                  </div>
                  <div className="news-text">
                    <h6>Lorem ipsum dolor, sit amet consectetur adipisicing elit. </h6>
                    <p>Jumat, 17/03/2023, 21.00 WIB</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="news3.html">
                <div className="news-card">
                  <div className='news-image'>
                    <img src={Hutdesa} alt="News Image" />
                  </div>
                  <div className="news-text">
                    <h6>Lorem ipsum dolor, sit amet consectetur adipisicing elit. </h6>
                    <p>Jumat, 17/03/2023, 21.00 WIB</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="news4.html">
                <div className="news-card">
                  <div className='news-image'>
                    <img src={Hutdesa} alt="News Image" />
                  </div>
                  <div className="news-text">
                    <h6>Lorem ipsum dolor, sit amet consectetur adipisicing elit. </h6>
                    <p>Jumat, 17/03/2023, 21.00 WIB</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="news5.html">
                <div className="news-card">
                  <div className='news-image'>
                    <img src={Hutdesa} alt="News Image" />
                  </div>
                  <div className="news-text">
                    <h6>Lorem ipsum dolor, sit amet consectetur adipisicing elit. </h6>
                    <p>Jumat, 17/03/2023, 21.00 WIB</p>
                  </div>
                </div>
              </a>
            </li>
          </ul>
          
          <br />

          <p className="orange-elipse">Other News</p>
          <ul>
            <li>
              <a href="news1.html">
                <div className="news-card">
                  <div className='news-image'>
                    <img src={Hutdesa} alt="News Image" />
                  </div>
                  
                  <div className="news-text">
                    <h6>Lorem ipsum dolor, sit amet consectetur adipisicing elit. </h6>
                    <p>Jumat, 17/03/2023, 21.00 WIB</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="news2.html">
                <div className="news-card">
                  <div className='news-image'>
                    <img src={Hutdesa} alt="News Image" />
                  </div>
                  <div className="news-text">
                    <h6>Lorem ipsum dolor, sit amet consectetur adipisicing elit. </h6>
                    <p>Jumat, 17/03/2023, 21.00 WIB</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="news3.html">
                <div className="news-card">
                  <div className='news-image'>
                    <img src={Hutdesa} alt="News Image" />
                  </div>
                  <div className="news-text">
                    <h6>Lorem ipsum dolor, sit amet consectetur adipisicing elit. </h6>
                    <p>Jumat, 17/03/2023, 21.00 WIB</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="news4.html">
                <div className="news-card">
                  <div className='news-image'>
                    <img src={Rumah} alt="News Image" />
                  </div>
                  <div className="news-text">
                    <h6>Lorem ipsum dolor, sit amet consectetur adipisicing elit. </h6>
                    <p>Jumat, 17/03/2023, 21.00 WIB</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="news5.html">
                <div className="news-card">
                  <div className='news-image'>
                    <img src={Rumah} alt="News Image" />
                  </div>
                  <div className="news-text">
                    <h6>Lorem ipsum dolor, sit amet consectetur adipisicing elit. </h6>
                    <p>Jumat, 17/03/2023, 21.00 WIB</p>
                  </div>
                </div>
              </a>
            </li>
          </ul>


        </div> */}
      </div>
        
    </section>
  )
}

export default DetailArtikel
import React, { Component, Suspense } from 'react';
import { Container } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import FeatherIcon from "feather-icons-react";
import TAHU from "../../assets/images/Komoditas/tahu.png"
import TEMPE from "../../assets/images/Komoditas/keripiktempe.png"
import RANGGINANG from "../../assets/images/Komoditas/rangginang.png"
import KRIPIK from "../../assets/images/Komoditas/anekakeripik.png"

class DetailKomoditas extends Component {
  constructor(props) {
    super(props);
  }
  //set preloader div
  PreLoader = () => {
    return (
      <div id='preloader'>
        <div id='status'>
          <div className='spinner'>
            <div className='bounce1'></div>
            <div className='bounce2'></div>
            <div className='bounce3'></div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { id } = this.props.match.params;
    const komoditas = [
      {
        img:TAHU,
        item:"Tahu",
        url:"Tahu", 
        text:"Gaharu adalah kayu berwarna kehitaman dan mengandung resin khas yang dihasilkan oleh sejumlah spesies pohon dari marga/genus Aquilaria, terutama A. malaccensis. Resin ini digunakan dalam industri wangi-wangian (parfum dan setanggi) karena berbau harum."
      },
      {
        img:TEMPE,
        item:"TEMPE",
        url:"kripiktempe",
        text:"Jagung (Zea mays ssp. mays) adalah salah satu tanaman pangan penghasil karbohidrat yang terpenting di dunia, selain gandum dan padi.Pada masa kini, jagung juga sudah menjadi komponen penting pakan ternak dan bahan dasar tepung maizena."
      },
      {
        img:RANGGINANG,
        item:"rangginang",
        url:"rangginang",
        text:"Karet adalah polimer hidrokarbon yang terbentuk dari emulsi kesusuan yang diperoleh dari getah tumbuhan dan ada juga hasil produksi secara sintetis. Sumber utama latex atau getah ini adalah pohon karet Hevea brasiliensis dengan cara melukai kulit pohon."
      },
      {
        img:KRIPIK,
        item:"Aneka Kripik",
        url:"kripik",
        text:"Karet adalah polimer hidrokarbon yang terbentuk dari emulsi kesusuan yang diperoleh dari getah tumbuhan dan ada juga hasil produksi secara sintetis. Sumber utama latex atau getah ini adalah pohon karet Hevea brasiliensis dengan cara melukai kulit pohon."
      }
  ]
  const findKomoditas = komoditas.find(data => {
    return data.url === id
  })
  
    return (
      <React.Fragment>
        <Suspense fallback={this.PreLoader()}>          
          <section className="detail-komoditas" ref={this.sectionRef}>
            <Container>
              <Link to={"/"} className="link-back">
                <FeatherIcon icon="arrow-left"/>
                Kembali
              </Link>
              <div className="item-komoditas">
                  <img src={findKomoditas.img} alt="" />
                  <div className="text-komoditas">
                      <h1>
                        {findKomoditas.item}
                      </h1>
                      <p>{findKomoditas.text}</p>
                  </div>
              </div>
              {/* <h3>Produksi Cabai Kami : 137 Kg / Hari</h3> */}
              </Container>
          </section>
        </Suspense>
      </React.Fragment>
    );
  }
}
export default withRouter(DetailKomoditas);
